import Api from 'utilities/api';
import React, { useEffect, useState } from 'react';
import colors from 'values/colors';
import get from 'lodash/get';
import i18next from 'i18n';
import styled from 'styled-components';
import { IdP, NotificationMessage } from '../types/index';
import { camelizeKeys, snakecaseKeys } from 'utilities/Utils';

export const SignInFormView = styled.form`
  margin-bottom: 12px;
  .form-group {
    margin-bottom: 12px;
    position: relative;
    .form-control {
      border-radius: 0;
      box-shadow: none;
      padding: 19px 16px;
      font-size: 1.3rem;
      &:focus {
        box-shadow: none;
      }
      &.email {
        border-bottom: none;
        position: relative;
        margin-bottom: -0.25px;
        border-radius: 10px 10px 0 0;
      }
      &.password {
        border-top: none;
        border-radius: 0 0 10px 10px;
      }
    }
    .form-border {
      width: 100%;
      height: 1px;
      background-color: ${colors.gray};
      position: absolute;
      z-index: 1;
    }
    button {
      height: 39px;
      border-radius: 10px;
      font-weight: bold;
      background-color: #5590e9;
      border: #548ee7;
      color: white;
      font-size: 1.4rem;
      transition: 0.3s;
      &:hover {
        background-color: #337ab7;
      }
      &.submitting {
        background-color: #337ab7;
      }
    }
  }
  .forget-message {
    display: block;
    font-weight: bold;
    text-align: center;
    color: #5590e9;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
`;

interface Props {
  readonly idp?: IdP;
  readonly setNotification: (arg: NotificationMessage) => void;
}

/**
 * ログインフォーム
 */
export const SignInForm: React.FC<Props> = (props) => {
  const csrfTokenNode = document.querySelector('head meta[name="csrf-token"]') as HTMLMetaElement;
  const csrfParamNode = document.querySelector('head meta[name="csrf-param"]') as HTMLMetaElement;
  // タグが見つからない場合はテストで警告が出ないように空文字を使う
  const csrfParamName = csrfParamNode ? csrfParamNode.content : '';
  const csrfTokenValue = csrfTokenNode ? csrfTokenNode.content : '';
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  /**
   * フォーム: メールアドレスの入力をした
   */
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (typeof e.target.value === 'string') { setEmail(e.target.value); }
  };

  /**
   * フォーム: パスワードの入力をした
   */
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (typeof e.target.value === 'string') { setPassword(e.target.value); }
  };

  const url = new URL(document.location.href);
  const params = new URLSearchParams(url.search);
  const changeTenantToken = params.get('change_tenant_token');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(!!changeTenantToken);

  /**
   * ログインをする
   */
  const submitSignInForm = (): void => {
    const SignInFormElement = document.querySelector('#sign_in_form') as HTMLFormElement;
    SignInFormElement.submit();
  };

  /**
   * ログインを押した
   */
  const handleSignIn = (): void => {
    setIsSubmitting(true);
  };

  /**
   * Enterキーを押した
   */
  const handleKeyUp = (e: React.KeyboardEvent): void => {
    const ENTER = 13;
    if (e.keyCode === ENTER && password.length >= 8 && email.length > 0) {
      handleSignIn();
    }
  };

  useEffect(() => {
    if (!isSubmitting) return;
    const signIn = async (): Promise<void> => {
      clearNotification();
      try {
        await Api.auth.signIn(snakecaseKeys({
          email,
          password,
          emailAuthenticationToken: params.get('email_authentication_token'),
          changeTenantToken,
        }));
        submitSignInForm();
      } catch (error) {
        setIsSubmitting(false);
        if (get(error, 'response.data.subdomain')) {
          const { host, protocol } = document.location;
          const { subdomain, emailAuthenticationToken } = camelizeKeys(get(error, 'response.data'));
          const subdomainSignInPageUrl = `${protocol}//${subdomain}.${host}/subdomains/sign_in?email_authentication_token=${emailAuthenticationToken}`;

          // ページ遷移をする
          document.location.href = subdomainSignInPageUrl;
        } else {
          const message = get(error, 'response.data.errors[0]', i18next.t('commons.errors.communicationError'));
          props.setNotification({ message, className: 'txt-caution' });
        }
      }
    };
    signIn();
    // eslint-disable-next-line
  }, [isSubmitting]);

  /**
   * メッセージを消す
   */
  const clearNotification = (): void => {
    props.setNotification({});
    // // パスワード再設定完了などのメッセージを消すための処理
    const flashMessage = document.querySelector('.flash-message');
    if (flashMessage) flashMessage.classList.add('hidden');
  };

  /**
   * 認証先のURL
   */
  const authUrl = props.idp?.samlRequest ? props.idp.url : '/users/sign_in';

  return (
    <SignInFormView id='sign_in_form' className='new_user' action={ authUrl } acceptCharset='UTF-8' method='post'>
      <input name={ csrfParamName } type='hidden' value={ csrfTokenValue } />
      {/** TOKIUM が IdP として動作した場合の変数 */}
      {
        props.idp?.samlRequest && (<>
          <input type='hidden' name='SAMLRequest' value={ props.idp.samlRequest } />
          <input type='hidden' name='RelayState' value={ props.idp.relayState } />
        </>)
      }
      {/** TODO: ログイン状態を維持するか、ユーザに選択させる */}
      <input className='hidden' type='check' value='true' readOnly name='user[remember_me]' />
      { changeTenantToken && <input className='hidden' type='hidden' value={ changeTenantToken } readOnly name='user[change_tenant_token]' /> }
      <div className='form-group'>
        <input
          type='email'
          name='user[email]'
          className='form-control email'
          autoFocus
          placeholder='ログインID (メールアドレス)'
          value={ email }
          onChange={ onChangeEmail }
          onKeyUp={ (e): void => handleKeyUp(e) }
          readOnly={ isSubmitting }
        />
        <div className='form-border' />
        <input
          type='password'
          name='user[password]'
          className='form-control password'
          autoComplete='off'
          placeholder='パスワード'
          value={ password }
          onChange={ onChangePassword }
          onKeyUp={ (e): void => handleKeyUp(e) }
          readOnly={ isSubmitting }
        />
      </div>
      <div className='form-group'>
        <button type='button' className={ `btn-block ${isSubmitting && 'submitting'}` } onClick={ (): void => handleSignIn() } disabled={ isSubmitting }>
          { isSubmitting ? <i className="fas fa-lg fa-spin fa-spinner" /> : 'ログイン' }
        </button>
      </div>
      <a className='forget-message' href='/users/password/new'>
        パスワードを忘れた方はこちら
      </a>
    </SignInFormView>
  );
};
